import React, { useState, useEffect } from "react";
// import TextContainer from './components/textCont/textContainer';
import Messages from './components/messages/messages';
import Input from './components/input/input';
import io from "socket.io-client";
import { dragElement } from '../../util';
import './styles.css';

const ENDPOINT = process.env.REACT_APP_SERVER_URL || 'http://localhost:5000';
let socket;

const ChatV2 = ({ screenWidth }) => {

  //chat socket
  const [name, setName] = useState("guest" + Math.floor(Math.random() * 1000));
  const [room, setRoom] = useState("daniel");
  const [users, setUsers] = useState([]);
  const [messages, setMessages] = useState([]);
  const [message, setMessage] = useState("");
  const [showJoin, setShowJoin] = useState(false);

  // useEffect(() => {
  //     const container = document.querySelector("#chatv2-outer-cont");
  //     const movable = document.querySelector("#chat-movable");
  //     if(movable) {
  //       movable.style.cursor = "move";
  //     }
  //     if(container) {
  //       container.addEventListener("touchmove", 
  //       dragElement(document.querySelector("#chatv2-outer-cont"), "chat-movable"), 
  //       true
  //       );
  //     }
  // }, []);


  const toggleJoin = () => {
    setShowJoin(!showJoin);
  }
  
  useEffect(() => {
    socket = io(ENDPOINT);
    socket.emit('join', { name, room }, (error) => {
      if(error) {
        alert(error);
      }
    });
  }, [ENDPOINT, name]);
  
  useEffect(() => {
    socket.on('message', message => {
      setMessages(messages => [ ...messages, message ]);
    });
    socket.on("roomData", ({ users }) => {
      setUsers(users);
    });
  }, []);

  const sendMessage = (event) => {
    event.preventDefault();
    if(message) {
      socket.emit('sendMessage', message, () => setMessage(''));
      setMessage('');
    }
  }

  return (
    <>
    {screenWidth < 600 ? (
      <div id="chatv2-outer-cont-small">
        <div className="Jarvis"></div>
        <div className="chatv2-inner-cont">
          <Messages messages={messages} name={name} />
          <Input toggleJoin={toggleJoin} message={message} setMessage={setMessage} sendMessage={sendMessage} />
        </div>
      </div>
      ) : (
        <div id="chatv2-outer-cont">
          <div className="Jarvis"></div>
          <div className="chatv2-inner-cont">
            <div id="chat-movable">
              <Messages messages={messages} name={name} />
            </div>
            <Input toggleJoin={toggleJoin} message={message} setMessage={setMessage} sendMessage={sendMessage} />
          </div>
        </div>
      )
    }
    </>
  );
}

export default ChatV2;