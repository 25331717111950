import React, { useState } from 'react';
import HomePage from './pages/home/index.js';
import ChatV2 from './components/chatV2';
import useScreenSize from './hooks/useScreenSize';
import sad from './assets/sad.png'
import happy from './assets/lovely.png'
import './App.css';

function App() {

  const [showChat, setShowChat] = useState(false);
  const { screenWidth } = useScreenSize();
  const [showPri, setShowPri] = useState("none");

  const getRandomInt = (max) => {
    return Math.floor(Math.random() * max);
  }

  const toggleChat = () => {
    setShowChat(!showChat);
    if(!showChat) {
      makeItRain(50);
    }
  }

  const createBlock = () => {
    const div = document.createElement("div")
    const a = document.createElement("a")
    a.classList.add("y");
    a.classList.add("block");
    a.style.backgroundColor = `rgb(${getRandomInt(255)}, ${getRandomInt(255)}, ${getRandomInt(255)})`;
    a.style.animation = `y ${getRandomInt(50)}s linear infinite alternate`;
    a.setAttribute("href", "https://youtu.be/dQw4w9WgXcQ");
    a.setAttribute("target", "_blank");
    div.classList.add("x");
    div.style.animation = `x ${getRandomInt(50)}s linear infinite alternate`;
    div.append(a);
    document.getElementById("blocks").append(div);
  }

  const makeItRain = (numb) => {
    for (let i = 0; i < numb; i++) {
      const div = document.createElement("div")
      const a = document.createElement("a")
      a.classList.add("y-circle");
      a.classList.add("heart");
      a.style.backgroundColor = `rgb(${getRandomInt(255)}, ${getRandomInt(255)}, ${getRandomInt(255)})`;
      a.style.animation = `y-circle ${getRandomInt(50)}s linear infinite alternate`;
      a.setAttribute("href", "https://youtu.be/dQw4w9WgXcQ");
      a.setAttribute("target", "_blank");
      div.classList.add("x-circle");
      div.style.animation = `x-circle ${getRandomInt(50)}s linear infinite alternate`;
      div.append(a);
      document.getElementById("circles").append(div);
    }
  }

  const onClickYesNo = (ans) => {
    if (ans === "Yes") {
      setShowPri("happy");
      makeItRain(100);
      alert("Date Night!! \nFebruary 16th 2024, 8:45PM\n11023 Montgomery Rd")
    } else {
      setShowPri("sad");
    }
  }
  return (
    <div>
      <div id="main-app" className="App">
        <div id="blocks"></div>
        <div id="circles"></div>
        <div className="x">
          <a rel="noreferrer" href="https://youtu.be/dQw4w9WgXcQ" target="_blank" className="block y"></a>
        </div>
        <div className="x2">
          <a rel="noreferrer" href="https://youtu.be/dQw4w9WgXcQ" target="_blank" className="block-2 y"></a>
        </div>
        <HomePage createBlock={createBlock} />
        <ChatV2 screenWidth={screenWidth}/>

          {/* <div className="pri">
            <div className="valentine-text">
              <h2>Priscila, will you be my valentine?</h2>
            </div>
            <div className="valentine-box">
              <div>
                <button onClick={() => onClickYesNo("Yes")}>Yes</button>
              </div>
              <div>
                <button onClick={() => onClickYesNo("No")}>No</button>
              </div>
            </div>
          </div>
        {showPri == "happy" && (

        <div className="pri">
        <div className="valentine-text">
          <img src={happy}></img>
          
        </div>
        </div>
        
        ) 
 } 
 {showPri == "sad" && (
          <div className="pri">
            <div className="valentine-text">
              <img src={sad}></img>
              
            </div>
          </div>
        )

        } */}

      </div>
    </div>

  );
}

export default App;
